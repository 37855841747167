<template>
  <div>
    <b-card-group deck>
      <b-card
          v-bind:header="is_edit?'Sửa whitelist':'Tạo mới whitelist'"
          header-tag="header"
      >
        <b-form v-if="show">
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                  id="input-dk-2"
                  label="Nhập số điện thoại Whitelist phát quà (*)"
                  label-for="ms-desc"
              >
                <b-form-textarea
                    id="ms-desc"
                    v-model="form.phones"
                    placeholder="Nhập số điện thoại VD:0988777444,0988333999 "
                    min="0"
                    rows="4"
                    :class="[{ 'is-invalid': form.errors.has('phones') }]"
                ></b-form-textarea>
                <has-error :form="form" field="phones"></has-error>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" >
              <b-form-group
                  id="client_id"
                  label="Merchant(*):"
                  label-for="input-1"
              >
                <b-form-select v-model="form.merchant_id" :class="[{ 'is-invalid': form.errors.has('merchant_id') }]" :disabled="is_disable">
                  <option value="">--Chọn--</option>
                  <option v-for="(client, index) in clients" :key="index" :value="client.id">{{ client.name }}</option>
                </b-form-select>
                <has-error :form="form" field="merchant_id"></has-error>
              </b-form-group>
            </div>
            <div class="col-md-4" >
              <b-form-group
                  id="event_id"
                  label="Chọn campaign (*):"
                  label-for="input-1"
              >
                <b-form-select v-model="form.event_id" :class="[{ 'is-invalid': form.errors.has('event_id') }]" :disabled="is_disable" @change="setCombos()">
                  <option value="">--Chọn--</option>
                  <option v-for="(client, index) in events" :key="index" :value="client.id">{{ client.name }}</option>
                </b-form-select>
                <has-error :form="form" field="event_id"></has-error>
              </b-form-group>
            </div>
            <div class="col-md-4" >
              <b-form-group
                  id="combo_voucher_id"
                  label="Chọn combo (*):"
                  label-for="input-1"
              >
                <b-form-select v-model="form.combo_voucher_id" :class="[{ 'is-invalid': form.errors.has('combo_voucher_id') }]" :disabled="is_disable">
                  <option value="">--Chọn--</option>
                  <option v-for="(combo, index) in combos" :key="index" :value="combo.id">{{ combo.title }}</option>
                </b-form-select>
                <has-error :form="form" field="combo_voucher_id"></has-error>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <b-form-group
                  id="input-dk-2"
                  label="Kích hoạt"
                  label-for="ms-title"
              >
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="form.status"
                    value="1"
                    unchecked-value="0"
                    size="lg"
                >
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12" style="text-align: right;">
              <b-button v-if="!is_edit" type="submit" variant="primary" style="margin-right: 10px;" @click="saveForm">
                Tạo mới
              </b-button>
              <b-button v-else type="submit" variant="primary" style="margin-right: 10px;" @click="updateWL">
                Cập nhật
              </b-button>
              <router-link v-if="is_edit" :to="'/combo-voucher/whitelist'">
                <b-button variant="danger">Hủy bỏ</b-button>
              </router-link>
              <b-button v-else  @click="onReset()" variant="danger">Làm mới</b-button>
            </div>
          </div>
        </b-form>
      </b-card>
    </b-card-group>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import { Form } from 'vform';
import {
  HasError,
} from 'vform/src/components/bootstrap5'
Vue.component("multiselect", Multiselect);
Vue.component(HasError.name, HasError)
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormMission",
  mixins: [Common],
  components: {
  },
  data() {
    return {
      form: new Form({
        id: '',
        phones: '',
        merchant_id: '',
        event_id: '',
        combo_voucher_id: '',
        status: 1,
      }),
      clients: [],
      combos: [],
      events: [],
      show: true,
      is_edit: false,
      is_disable: false,
    };

  },
  computed: {
    clientID () {
      return this.form.merchant_id;
    }
  },
  watch: {
    clientID () {
      this.combos = [];
      this.events = [];
      this.loadEvents();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách whitelist ", route: "/combo-voucher/whitelist" },
      { title: "Thêm whitelist" },
    ]);
  },
  methods: {
    setCombos(reset = true){
      let event =  this.events.filter(event => event.id === this.form.event_id)[0];
      if (reset){
        this.form.combo_voucher_id='';
      }
      this.combos = event.combos
    },

    saveForm () {
      this.$bus.$emit("show-loading", true);
      this.form.submit('post', '/combo-voucher/whitelist')
          .then(({data}) => {
            this.$bus.$emit("show-loading", false);
            if (1 === data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: data.message,
              });
              this.$router.push('/combo-voucher/whitelist');
            }
          })
          .catch(() => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: "Vui lòng kiểm tra lại form nhập liệu.",
            });
          })
    },
    async getClients() {
      let response = await WalletCampaignRepository.getClients();
      this.clients = response.data.data;
    },
    async loadEvents () {
      try {
        this.$bus.$emit("show-loading", true);
        let filter = {
          client_id: this.form.merchant_id,
          is_show_all: true
        }
        let response = await WalletCampaignRepository.getEvents(filter);
        this.$bus.$emit("show-loading", false);
        this.events = response.data.data;
        this.setCombos(false);
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async loadCombo (id) {
      try {
        let response = await WalletCampaignRepository.getComboVoucherWL(id);
        if (response.data.error_code === 0) {
          let data = response.data.data;
          this.form.fill(data);

        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
    updateWL() {
      this.form.submit('post', '/combo-voucher/whitelist/'+this.form.id)
          .then(({data}) => {
            if (1 === data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: data.message,
              });
              this.$router.push('/combo-voucher/whitelist');
            }
          })
          .catch((error) => {
            this.$notify({
              group: "foo",
              type: "error",
              title: error,
            });
          })
    },
    onReset() {
      this.form.phones = '';
      this.form.merchant_id = '';
      this.form.event_id = '';
      this.form.combo_voucher_id = '';
      this.form.status = 1;
      this.form.phones = '';
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
      //this.createImage(files[0]);
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.form.img = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
  },
  created() {
    this.getClients();
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadCombo(this.$route.params.id)
    }

  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
.datetime-picker div input{
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
